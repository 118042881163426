<template>
    <div>
        <CCard class="shadow-sm m-2">
            <CCardBody>
                <CRow>
                    <CCol sm="1" col="2">
                        <img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
                    </CCol>
                    <CCol sm="9" col="8">
                        <select class="custom-select text-black" v-model="shop" disabled>
                            <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                                {{ shop.shopName }} - {{ shop.branchName }}
                            </option>
                        </select>
                    </CCol>
                    <div class="col-md-2 col-sm-2 col-2 text-right" v-if="loadingButton == true">
                        <CButton block color="light" @click="goback()">
                            <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
                        </CButton>
                    </div>
                    <div class="col-md-2 col-sm-2 text-right" v-else>
                        <CButton block color="success" disabled>
                            <CSpinner color="white" size="sm" />{{ $t('loading') }}
                        </CButton>
                    </div>
                </CRow>
            </CCardBody>
        </CCard>
        <CCol md="12" lg="12" class="mt-4">
            <CCard class="shadow-sm">
                <CCardBody>
                    <CAlert color="success" v-if="isSuccess === true">
                        {{ $t('createProductSuccess') }}
                    </CAlert>
                    
                    <div class="row">
                        
                        <div class="col-md-10 col-sm-9 col-9">
                            <h4 class="mt-2">
                                {{ $t('addOption') }}
                            </h4>
                           
                        </div>
                        <div class="col-md-2 col-sm-3 col-3 text-right">
                            <CButton block color="success font-weight-normal" v-if="loadingSaveButton === false"
                                @click="handlePopup" >
                                {{ $t('save') }}
                            </CButton>
                            <CButton block color="success font-weight-normal" v-else-if="loadingSaveButton === true">
                                <CSpinner color="white" size="sm" />{{ $t('save') }}
                            </CButton>
                        </div>
                        
                    </div>
                    <div class="ml-5"> 
                            <CRow>
                                <CCol class="col-form-label" md="2" style=" margin-top: 2%;">{{
                                    $t('txtOption') }}</CCol>
                                <CCol style="margin-top: 2%;" md="10" class="pr-5">
                                    <CInput v-if="validateName" v-model="name" :placeholder="$t('fillOption')"
                                        :is-valid="validator" :invalid-feedback="$t('fillOption')" />
                                    <CInput v-else v-model="name" :placeholder="$t('fillOption')" />
                                </CCol>
                            </CRow>
                            <CRow class="mt-2 mb-3">
                                <CCol class="col-form-label " md="2" style="">{{ $t('stockUnit') }}
                                </CCol>
                                <CCol md="10" class="pr-5">
                                    <CInput v-model="price" type="number" :placeholder="$t('stockUnit')" />
                                </CCol>
                            </CRow>
                            <!-- <CRow class="mt-2 mb-3">
                                <CCol class="col-form-label " md="2" style="">
                                    {{ $t('morePrice') }} 

                                </CCol>
                                <CCol md="10">
                                    <div >
                                        <CCallout color="primary" class="">
                                          
                                                <div class="row mb-2">

                                                    <div class="col-lg-2 col-7 ">
                                                        <p class="mt-2 ml-3 text-left">{{ $t('deliveryOnDemand') }}</p>
                                                    </div>

                                                    <div class="col-lg-5 col-5 text-left row">
                                                        <label
                                                            class="col-sm-4 col-lg-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                            {{ $t('difference') }}
                                                        </label>
                                                        <div class="col-sm-8 col-lg-8">
                                                            <input class="form-control text-danger"
                                                                :value="getDifferent(grabfood.deliveryOnDemand)"
                                                                v-if="Number(getDifferent(grabfood.deliveryOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(grabfood.deliveryOnDemand)))"
                                                                disabled />
                                                                <input class="form-control  " :value="getDifferent(grabfood.deliveryOnDemand)"
                                                                disabled
                                                                v-else-if="Number(getDifferent(grabfood.deliveryOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(grabfood.deliveryOnDemand))) === 0" />
                                                                <input class="form-control text-success"
                                                                :value="getDifferent(grabfood.deliveryOnDemand)" disabled v-else />

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-5 col-6 text-left row ">
                                                        <label
                                                            class="col-sm-3 col-lg-3 col-form-label text-right text-dark w-100 w-sm-auto">
                                                            {{ $t('pricePackage') }}
                                                        </label>
                                                        <div class="col-sm-9 col-lg-9" >
                                                            <input class="form-control " type="number" v-model="grabfood.deliveryOnDemand" />

                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="row mb-2">

                                                    <div class="col-lg-2 col-7">
                                                        <p class="mt-2 ml-3 text-left">{{ $t('deliveryScheduled') }}</p>
                                                    </div>

                                                    <div class="col-lg-5 col text-left row ">
                                                        <label
                                                            class="col-sm-4 col-lg-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                            {{ $t('difference') }}
                                                        </label>
                                                        <div class="col-sm-8 col-lg-8">
                                                            <input class="form-control text-danger"
                                                                :value="getDifferent(grabfood.deliveryScheduled)"
                                                                v-if="Number(getDifferent(grabfood.deliveryScheduled) < 0) || Number.isNaN(parseInt(getDifferent(grabfood.deliveryScheduled)))"
                                                                disabled />
                                                                <input class="form-control  " :value="getDifferent(grabfood.deliveryScheduled)"
                                                                disabled
                                                                v-else-if="Number(getDifferent(grabfood.deliveryScheduled) == 0) || Number.isNaN(parseInt(getDifferent(grabfood.deliveryScheduled))) === 0" />
                                                                <input class="form-control text-success"
                                                                :value="getDifferent(grabfood.deliveryScheduled)" disabled v-else />


                                                        </div>
                                                    </div>

                                                    <div class="col-lg-5 col text-left row">
                                                        <label
                                                            class="col-sm-3 col-lg-3 col-form-label text-right text-dark w-100 w-sm-auto">
                                                            {{ $t('pricePackage') }}
                                                        </label>
                                                       
                                                        <div class="col-sm-9 col-lg-9">
                                                            <input class="form-control " type="number" v-model="grabfood.deliveryScheduled" />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" row mb-2">

                                                    <div class="col-lg-2 col-2">
                                                        <p class="mt-2 ml-3 text-left">{{ $t('selfPickUpOnDemand') }}</p>
                                                    </div>

                                                    <div class="col-lg-5 col text-left row ">
                                                        <label
                                                            class="col-sm-4 col-lg-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                            {{ $t('difference') }}
                                                        </label>
                                                        <div class="col-sm-8 col-lg-8">
                                                            <input class="form-control text-danger"
                                                                :value="getDifferent(grabfood.selfPickUpOnDemand)"
                                                                v-if="Number(getDifferent(grabfood.selfPickUpOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(grabfood.selfPickUpOnDemand)))"
                                                                disabled />
                                                                <input class="form-control  " :value="getDifferent(grabfood.selfPickUpOnDemand)"
                                                                disabled
                                                                v-else-if="Number(getDifferent(grabfood.selfPickUpOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(grabfood.selfPickUpOnDemand))) === 0" />
                                                                <input class="form-control text-success"
                                                                :value="getDifferent(grabfood.selfPickUpOnDemand)" disabled v-else />
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-5 col text-left row ">
                                                        <label
                                                            class="col-sm-3 col-lg-3 col-form-label text-right text-dark w-100 w-sm-auto">
                                                            {{ $t('pricePackage') }}

                                                        </label>
                                                        <div class="col-sm-9 col-lg-9" >
                                                            <input class="form-control " type="number" v-model="grabfood.selfPickUpOnDemand" />

                                                        </div>
                                        
                                                    </div>
                                                </div>
                                                <div class=" row mb-2">
                                                    <div class="col-lg-2 col-2">
                                                        <p class="mt-2 ml-3 text-left">{{ $t('dineInOnDemand') }}</p>
                                                    </div>

                                                    <div class="col-lg-5 col text-left row ">
                                                        <label
                                                            class="col-sm-4 col-lg-4 col-form-label text-right text-dark w-100 w-sm-auto">
                                                            {{ $t('difference') }}
                                                        </label>
                                                        <div class="col-sm-8 col-lg-8">
                                                            <input class="form-control text-danger"
                                                                :value="getDifferent(grabfood.dineInOnDemand)"
                                                                v-if="Number(getDifferent(grabfood.dineInOnDemand) < 0) || Number.isNaN(parseInt(getDifferent(grabfood.dineInOnDemand)))"
                                                                disabled />
                                                                <input class="form-control  " :value="getDifferent(grabfood.dineInOnDemand)"
                                                                disabled
                                                                v-else-if="Number(getDifferent(grabfood.dineInOnDemand) == 0) || Number.isNaN(parseInt(getDifferent(grabfood.dineInOnDemand))) === 0" />
                                                                <input class="form-control text-success"
                                                                :value="getDifferent(grabfood.dineInOnDemand)" disabled v-else />
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-5 col text-left row ">
                                                        <label
                                                            class="col-sm-3 col-lg-3 col-form-label text-right text-dark w-100 w-sm-auto">
                                                            {{ $t('pricePackage') }}

                                                        </label>
                                                        <div class="col-sm-9 col-lg-9" >
                                                            <input class="form-control " type="number" v-model="grabfood.dineInOnDemand" />

                                                        </div>

                                                    </div>
                                                </div>
                                        </CCallout>
                                       
                                    </div>
                                </CCol>
                            </CRow> -->
                        </div>
                    

                </CCardBody>
            </CCard>
        </CCol>
    </div>
</template>
<script>
import util from '@/util/util'
import { mapGetters } from 'vuex'
import local from '@/services/local'
import permis from '@/util/permission'

export default {
    components: {},
    data() {
        return {
            isSuccess: false,
            SKUName: '',
            keyword: '',
            data: [],
            dataSKUbytype: [],
            itemObjectId: [],
            validateShop: false,
            shop: this.$store.getters.shopObjectId,
            addlist: [],
            quantity: 1,
            countAddList: 0,
            loadingButton: true,
            price: 0,
            name: '',
            itemId: '',
            itemObjectId: '',
            shop: this.$store.getters.shopObjectId,
            sortdata: 'name',
            loadingSaveButton: false,
            validateName: '',
            validatePrice: '',
            grabfood : {
                deliveryOnDemand: 0,
                    deliveryScheduled: 0,
                    selfPickUpOnDemand: 0,
                    dineInOnDemand : 0
            },
            isDeliveryActive : false,
            genObjectId : ''
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date']),
        isPermission() {
            let path = '/product'
            return permis.findPermissionRead('product', path)
        },
        isEditData() {
            let path = '/product'
            return permis.findPermissionEdit('product', path)
        },
        isDeleteData() {
            let path = '/product'
            return permis.findPermissionRemove('product', path)
        },
        uid() {
            let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            return shop.uid
        },
        
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        fields() {
            return [{ key: 'name', label: 'ชื่อสินค้า' }]
        },
        items() {
            let data = this.data.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
            let detail = []
            let start = this.meta_data.current_page * 50 - 50
            for (let i = 0; i < data.length; i++) {
                let name = data[i].name

                let price = '-'

                // if (typeof data[i].price === 'number') {
                //     price = data[i].price.toFixed(2)
                // }
                detail.push({
                    num: start + i + 1,
                    name: name,
                    id: data[i].id,
                    price: price,
                    objectId: data[i].objectId,
                })
            }
            return detail
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE
        },
    },
    created() {
        this.shop = this.shops.find((i) => i.objectId === this.shop)
        this.generateObjectId()
    },
    methods: {
        getDifferent(item) {
            let difference = item - this.price;
            return difference;
        },
        generateObjectId() {
            const uid = `${localStorage.getItem('shopsUid')}`
    
            local({
                method: 'get',
                url: '/api/v1.0/' + uid + '/objectId/generate',
            })
                .then((res) => {
                    
                this.genObjectId = res.data.data.objectId
                })
            .catch((error) => {
            console.log(error)
            })
        },
        goback() {
            this.$router.push('/product/option')
        },
        savedata() {
            this.loadingSaveButton = true
            this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            const uid = `${localStorage.getItem('shopsUid')}`
            const shopObjectId = this.shopObjectId
            let data = []
            let availableStatus = 'UNAVAILABLE'
            
            if (this.isDeliveryActive ) {
                availableStatus = 'AVAILABLE'
            } else {
                availableStatus == 'UNAVAILABLE'  
            }
            if(this.grabfood.id === "" || this.grabfood.id === undefined){
                this.grabfood.id = this.genObjectId
            }
            
            if (typeof this.grabfood.deliveryOnDemand !== 'number' || isNaN(this.grabfood.deliveryOnDemand)) {
            this.grabfood.deliveryOnDemand = util.currencyStringToNumber(this.grabfood.deliveryOnDemand);
            }
            if (typeof this.grabfood.deliveryScheduled !== 'number' || isNaN(this.grabfood.deliveryScheduled)) {
            this.grabfood.deliveryScheduled = util.currencyStringToNumber(this.grabfood.deliveryScheduled);
            }
            if (typeof this.grabfood.selfPickUpOnDemand !== 'number' || isNaN(this.grabfood.selfPickUpOnDemand)) {
            this.grabfood.selfPickUpOnDemand = util.currencyStringToNumber(this.grabfood.selfPickUpOnDemand);
            }
            if (typeof this.grabfood.dineInOnDemand !== 'number' || isNaN(this.grabfood.dineInOnDemand)) {
            this.grabfood.dineInOnDemand = util.currencyStringToNumber(this.grabfood.dineInOnDemand);
            }

            if (this.itemObjectId == '') {
                data = {
                    name: this.name,
                    price: parseFloat(this.price),
                    uid: uid,
                    shop: {
                        objectId: this.shop.objectId,
                        id: this.shop.id,
                        branchName: this.shop.branchName,
                    },
                    // availableStatus : availableStatus,
                    // grabfood : this.grabfood,
                }
            } else {
                data = {
                    id: this.itemId, //item id
                    objectId: this.itemObjectId, // objectid item
                    name: this.name,
                    price: parseFloat(this.price),
                    uid: uid,
                    shop: {
                        objectId: this.shop.objectId,
                        id: this.shop.id,
                        branchName: this.shop.branchName,
                    },
                    // availableStatus : availableStatus,
                    // grabfood : this.grabfood,
                }
            }
            const headers = { shopObjectId: shopObjectId }

            local({
                method: 'post',
                url: '/api/V1.0/' + uid + '/option/item/update',
                data: data,
                headers: headers,
            })
                .then(
                    setTimeout(() => {
                        // this.isSuccess = true
                        this.name = ''
                        this.loadingSaveButton = false
                        this.popupModal = false
                        this.addOptionModal = false
                        this.editOptionModal = false
                        this.$router.push('/product/option')
                    }, 1000)
                )
                .catch((error) => {
                    console.log(error)
                    this.loadingSaveButton = true
                })
        },
        validator(val) {
            return val ? val.length >= 1 : false
        },
        handlePopup() {
            if (this.name == '') {
                this.validateName = 'กรุณากรอกชื่อตัวเลือก'
            }  else {
                this.validateName = ''
                this.savedata()
            }
        },
    },
}
</script>
  
<style>

@media(min-height: 400px) {
  .body-bom {
    max-height: 320px
  }

  .cardadd {
    min-height: 400px;
    max-height: 400px;
    /* border-colo #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}
@media(min-height: 600px) {
  .body-bom {
    max-height: 380px
  }

  .cardadd {
    min-height: 500px;
    max-height: 500px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}
@media(min-height: 700px) {
  .body-bom {
    max-height: 400px
  }

  .cardadd {
    min-height: 520px;
    max-height: 520px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}

@media(min-height: 900px) {
  .body-bom {
    max-height: 520px
  }

  .cardadd {
    min-height: 640px;
    max-height: 640px;
    /* border-color: #3abc98; */
    border-color: rgb(218, 216, 216);
  }
}
</style>
  